import api from "../httpClient.js";

export async function getEventosMembros() {
  const response = await api.get("/eventosmembros");
  return response.data.eventosmembros;
}

export async function getEventosMembrosNotEqual(evento_id) {
  const response = await api.get(`/eventosmembros/${evento_id}/notequal`);
  return response.data.usuarios;
}

export async function deleteEventosMembros(id) {
  return api.delete(`/eventosmembros/${id}`);
}

export async function getEventosMembrosPorEvento(filtros) {
  const response = await api.post(`/eventosmembros${filtros}`);
  return response.data.eventosmembros;
}

export async function getEventosMembro(id) {
  const response = await api.get(`/eventosmembros/${id}`);
  return response.data.eventosmembro;
}

export function putEventosMembro(id, EventosMembro) {
  let body = {};
  for (let key in EventosMembro) {
    body[key] = EventosMembro[key];
  }
  return api.put(`/eventosmembros/${id}`, body);
}

export function postEventosMembro(EventosMembro) {
  let body = {};
  for (let key in EventosMembro) {
    body[key] = EventosMembro[key];
  }
  return api.post("/eventosmembros/add", body);
}
