<template>
  <!-- style="height: 401px; max-height: 401px;" -->
  <v-card-text v-if="!lodash.isEmpty(evento) && membros">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" md="8" class="d-flex flex-column">
        <v-card-title class="px-0 py-2 subtitle-1">
          <v-icon color="toolbar" class="mr-2">book</v-icon>
          {{ evento.tipoevento }}
        </v-card-title>

        <v-divider></v-divider>

        <!-- Descricao -->
        <v-card-title class="px-0 pb-0 subtitle-1">
          <v-icon color="toolbar" class="mr-2">notes</v-icon
          >{{ $tc("global.descricao") }}
        </v-card-title>
        <v-card-text class="px-0">
          <v-textarea
            background-color="lightgray"
            flat
            solo
            v-model="evento.descricao"
            readonly
            :rows="3"
            auto-grow
            hide-details
          ></v-textarea>
        </v-card-text>

        <!-- Feedback -->
        <template v-if="evento.status === 4">
          <v-card-title class="pa-0 subtitle-1">
            <v-icon color="toolbar" v-if="!evento.feedback_reacao">
              face
            </v-icon>

            <v-icon v-if="evento.feedback_reacao == 1" color="red accent-4">
              sentiment_very_dissatisfied
            </v-icon>

            <v-icon v-if="evento.feedback_reacao == 2" color="orange darken-4">
              sentiment_dissatisfied
            </v-icon>

            <v-icon v-if="evento.feedback_reacao == 3" color="amber darken-3">
              sentiment_satisfied
            </v-icon>

            <v-icon v-if="evento.feedback_reacao == 4" color="green darken-2">
              sentiment_very_satisfied
            </v-icon>

            <v-icon v-if="evento.feedback_reacao == 5" color="error">
              favorite
            </v-icon>
            <span class="ml-2">Feedback</span>
          </v-card-title>
          <v-card-text class="px-0" v-if="evento.feedback_comentario">
            <v-textarea
              background-color="lightgray"
              flat
              solo
              v-model="evento.feedback_comentario"
              readonly
              :rows="3"
              auto-grow
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-text class="px-0" v-else>
            <v-textarea
              background-color="lightgray"
              flat
              solo
              :value="$tc('msg.feedbacknao')"
              readonly
              :rows="1"
              hide-details
            ></v-textarea>
          </v-card-text>
        </template>

        <template v-if="cliente_end">
          <v-card-title class="pa-0 subtitle-1 ">
            <v-icon color="toolbar" class="mr-2">location_on</v-icon>Endereço
          </v-card-title>
          <v-card-text class="lightgray px-3 py-2 rounded mb-4">
            {{ cliente_end }}
          </v-card-text>
        </template>

        <template v-if="evento.localizacao">
          <v-card-title class="pa-0 subtitle-1 ">
            <v-icon color="toolbar" class="mr-2">map</v-icon>Localização
          </v-card-title>
          <v-card-text
            class="d-flex lightgray px-3 py-2 rounded mb-4 align-center justify-space-between"
          >
            {{ evento.localizacao }}
            <v-btn
              icon
              small
              :href="`https://maps.google.com/?q=${evento.localizacao}`"
              target="_blank"
            >
              <v-icon>link</v-icon>
            </v-btn>
          </v-card-text>
        </template>

        <!-- Tags -->
        <v-card-title class="pa-0 subtitle-1 ">
          <v-icon color="toolbar" class="mr-2">label</v-icon>Tags
        </v-card-title>
        <v-card-text class="px-0 pb-0">
          <v-combobox
            v-model="selectTags"
            :items="tags"
            :search-input.sync="search"
            item-text="tag"
            item-value="tag"
            :return-object="false"
            :placeholder="$tc('global.add') + ' ' + $tc('pages.tags')"
            multiple
            chips
            solo
            flat
            hide-details
            :disabled="loadingTags"
            dense
          >
            <template v-slot:no-data>
              <v-list-item @click="addTagGeral(search)" class="my-n2 py-1">
                <span class="subheading">{{ $tc("eventos.paraadd") }} </span>
                <v-chip label class="mx-2">
                  {{ search }}
                </v-chip>
                <span class="subheading">{{ $tc("eventos.alistageral") }}</span>
                <v-spacer></v-spacer>
                <v-icon class="ml-2">add</v-icon>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip :input-value="selected" small label color="lightgray">
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon small @click="parent.selectItem(item)">close</v-icon>
              </v-chip>
            </template>
          </v-combobox>
        </v-card-text>
      </v-col>

      <v-col cols="12" md="4" class="pl-md-8 pt-2" style="position: relative">
        <template v-if="membros">
          <!-- Membros -->
          <v-card-title class="pa-0 body-2">
            <span style="line-height: 2.05;">
              {{ $tc("eventos.membro", 2) }}
            </span>
            <v-spacer></v-spacer>
            <!-- Adicionar Membro -->
            <template
              v-if="
                evento.status != 3 && evento.status != 4 && evento.status != -1
              "
            >
              <v-btn small icon @click="dialogMembro = true">
                <v-icon color="toolbar">add</v-icon>
              </v-btn>
            </template>
          </v-card-title>
          <v-card-text class="pa-0">
            <div v-for="(membro, index) in membros" :key="index">
              <!-- Membros Criador -->
              <template v-if="membro.criador">
                <v-chip
                  class="pa-2 pl-4"
                  style="width: 100%"
                  color="lightgray"
                  label
                >
                  <v-icon color="toolbar" left>person_add</v-icon>
                  <span class="caption ml-auto">{{
                    excerpt(buscaUsuario(membro.user_id))
                  }}</span>
                </v-chip>
              </template>

              <!-- Membros Responsavel -->
              <template v-if="membro.responsable">
                <v-chip
                  class="pa-2 pl-4 mt-2"
                  style="width: 100%"
                  color="lightgray"
                  label
                  close
                  close-icon="close"
                  @click:close="delProduto(membro.id)"
                >
                  <v-icon color="toolbar" left>how_to_reg</v-icon>
                  <span class="caption ml-auto">{{
                    excerpt(buscaUsuario(membro.user_id))
                  }}</span>
                </v-chip>
              </template>

              <!-- Membros Auxiliar -->
              <template v-if="!membro.responsable && index != 0">
                <v-chip
                  class="pa-2 pl-4 mt-2"
                  style="width: 100%"
                  color="lightgray"
                  label
                  close
                  close-icon="close"
                  @click:close="delProduto(membro.id)"
                >
                  <v-icon color="toolbar" left>person</v-icon>
                  <span class="caption ml-auto">{{
                    excerpt(buscaUsuario(membro.user_id))
                  }}</span>
                </v-chip>
              </template>
            </div>
          </v-card-text>
        </template>

        <!-- Agendamento Titulo -->
        <v-card-title class="pa-0 body-2 mt-3">
          <span style="line-height: 2.05;">{{
            $tc("eventos.agendamento")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn
            small
            icon
            color="toolbar"
            @click="dialogAgendar = true"
            v-if="
              evento.status != 4 && evento.status != -1 && !evento.agendado_data
            "
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Agendamento -->
        <v-card-text v-if="evento.agendado_data" class="pa-0">
          <v-chip
            class="py-2 pl-3 chip100"
            style="width: 100%"
            color="lightgray"
            label
          >
            <div
              style="width: 100%"
              class="d-flex align-center"
              :class="{
                'justify-space-between':
                  evento.status != 4 && evento.status != -1,
              }"
            >
              <v-icon color="toolbar">event</v-icon>

              <div
                class="caption"
                :class="{
                  'ml-2': evento.status === 4 || evento.status === -1,
                }"
              >
                {{ evento.agendado_data | dateFormat("dd/MM/yyyy") }}
                {{ evento.agendado_hora ? "as" : "" }}
                {{ evento.agendado_hora }}
              </div>

              <v-btn
                class=""
                depressed
                small
                icon
                color="toolbar"
                @click="dialogAgendar = true"
                v-if="evento.status != 4 && evento.status != -1"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </div>
          </v-chip>
        </v-card-text>
        <v-card-text v-else class="pa-0">
          <v-sheet color="lightgray" class="caption text-center pa-1">
            {{ $tc("eventos.semdata") }}
          </v-sheet>
        </v-card-text>

        <!-- Tempo Usado Titulo -->
        <v-card-title class="pa-0 body-2 mt-3">
          <span style="line-height: 2.05;">
            {{ $tc("eventos.tempousado") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            small
            icon
            color="toolbar"
            @click="dialogTempos = true"
            v-if="
              evento.status != 4 &&
                evento.status != -1 &&
                !evento.horario_fim &&
                !evento.horario_inicio
            "
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Tempo Usado -->
        <v-card-text
          v-if="evento.horario_inicio && evento.horario_fim"
          class="pa-0"
        >
          <v-chip
            class="py-2 pl-3 chip100"
            style="width: 100%"
            color="lightgray"
            label
          >
            <div
              style="width: 100%"
              class="d-flex align-center"
              :class="{
                'justify-space-between':
                  evento.status != 4 && evento.status != -1,
              }"
            >
              <v-icon color="toolbar">schedule</v-icon>

              <div
                class="caption"
                :class="{
                  'ml-2': evento.status === 4 || evento.status === -1,
                }"
              >
                De: {{ evento.horario_inicio }} Ate: {{ evento.horario_fim }}
              </div>

              <v-btn
                class=""
                depressed
                small
                icon
                color="toolbar"
                @click="dialogTempos = true"
                v-if="evento.status != 4 && evento.status != -1"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </div>
          </v-chip>
        </v-card-text>
        <v-card-text v-else class="pa-0">
          <v-sheet color="lightgray" class="caption text-center pa-1">
            {{ $tc("eventos.semtempousado") }}
          </v-sheet>
        </v-card-text>

        <!-- Ações Titulo -->
        <v-card-title class="pa-0 body-2 mt-3">
          <span style="line-height: 2.05;">{{ $tc("global.acoes") }}</span>
        </v-card-title>

        <!-- Ações -->
        <v-card-text class="px-0 pt-0">
          <v-chip
            v-if="evento.status === 4"
            class="pa-2 pl-4"
            style="width: 100%"
            color="lightgray"
            label
            @click="getFeedback"
          >
            <v-icon color="toolbar" left>mood</v-icon>
            <span class="caption ml-auto">Feedback</span>
          </v-chip>
          <v-chip
            class="pa-2 pl-4 mt-2"
            style="width: 100%"
            color="lightgray"
            label
            @click="openDialogConfirmation('cancelar')"
            v-if="evento.status != -1"
          >
            <v-icon color="toolbar" left>close</v-icon>
            <span class="caption ml-auto">{{ $tc("botoes.cancelar") }}</span>
          </v-chip>
          <v-chip
            v-if="
              evento.status != -1 && evento.status != 3 && evento.status != 4
            "
            class="pa-2 pl-4 mt-2"
            style="width: 100%"
            color="lightgray"
            label
            @click="openDialogConfirmation('enviar_aprovacao')"
          >
            <v-icon color="toolbar" left>done</v-icon>
            <span class="caption ml-auto">
              {{ $tc("eventos.enviaraprovacao") }}
            </span>
          </v-chip>
          <v-chip
            v-if="evento.status === 3"
            class="pa-2 pl-4 mt-2"
            style="width: 100%"
            color="lightgray"
            label
            @click="openDialogConfirmation('aprovar')"
          >
            <v-icon color="toolbar" left>done_all</v-icon>
            <span class="caption ml-auto">
              {{ $tc("eventos.aprovar") }}
            </span>
          </v-chip>
        </v-card-text>
      </v-col>
    </v-row>

    <!-- Modal para agendar evento -->
    <v-dialog v-model="dialogAgendar" width="500">
      <v-card :loading="loadingAgendamento" :disabled="loadingAgendamento">
        <v-card-title class="lightgray subtitle-1">
          <v-icon color="toolbar" class="mr-2">schedule</v-icon
          >{{ $tc("eventos.agendar") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogAgendar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-6">
          <!-- Agendar Para -->
          <v-menu
            v-model="agendarPara"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="agendadoParaFormatted"
                :label="$tc('eventos.agendarpara')"
                append-icon="event"
                dense
                clearable
                readonly
                filled
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              v-model="evento.agendado_data"
              @input="agendarPara = false"
            ></v-date-picker>
          </v-menu>

          <!-- Agendar Hora -->

          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mt-4"
                v-model="time"
                label="Horario"
                prepend-inner-icon="access_time"
                readonly
                filled
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              format="24hr"
              min="07:00"
              max="19:00"
              @click:minute="$refs.menu.save(time)"
            ></v-time-picker>
          </v-menu>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="toolbar"
            depressed
            class="white--text"
            small
            @click="updateAgendamento"
            :disabled="disableAgendamento"
          >
            {{ $tc("botoes.salvar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para adicionar um membro ao evento -->
    <v-dialog v-model="dialogMembro" width="500">
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          <v-icon color="toolbar" class="mr-2">people</v-icon
          >{{ $tc("global.add") }} {{ $tc("eventos.membro") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogMembro = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="py-6">
          <v-select
            v-model="membro.user_id"
            :items="usersNotListed"
            clearable
            item-text="name"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('pages.usuario')"
          ></v-select>
          <v-select
            v-model="membro.veiculo_id"
            :items="veiculos"
            clearable
            item-text="placa"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('pages.veiculo')"
            class="mt-6"
          ></v-select>
          <!-- v-if="isAdmin || responsavel || criador" -->
          <v-card
            v-if="isAdmin || criador"
            flat
            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
            height="56px"
            class="pa-3 mt-6 d-flex justify-space-between align-center"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
              >{{ $tc("empresas.responsavel") }}</v-subheader
            >

            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="false"
              :true-value="true"
              v-model="membro.responsable"
            ></v-switch>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="toolbar"
            :disabled="!temVeiculo"
            small
            dark
            depressed
            @click="addMembro"
          >
            {{ $tc("global.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para ajustar tempo de trabalhado usado no evento -->
    <v-dialog v-model="dialogTempos" persistent width="500">
      <v-card :loading="loadingTempo">
        <v-card-title class="subtitle-1 lightgray">
          {{ $tc("eventos.horariogasto") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTempos = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-menu
            ref="horario_inicio"
            v-model="menu_horario_inicio"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="evento.horario_inicio"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="evento.horario_inicio"
                label="Horario Inicio"
                prepend-inner-icon="access_time"
                readonly
                filled
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu_horario_inicio"
              v-model="evento.horario_inicio"
              full-width
              format="24hr"
              min="07:00"
              max="19:00"
              @click:minute="$refs.horario_inicio.save(evento.horario_inicio)"
            ></v-time-picker>
          </v-menu>

          <v-menu
            ref="horario_fim"
            v-model="menu_horario_fim"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="evento.horario_fim"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mt-4"
                v-model="evento.horario_fim"
                label="Horario Fim"
                prepend-inner-icon="access_time"
                readonly
                filled
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu_horario_fim"
              v-model="evento.horario_fim"
              full-width
              format="24hr"
              min="07:00"
              max="19:00"
              @click:minute="$refs.horario_fim.save(evento.horario_fim)"
            ></v-time-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-3" small text @click="dialogTempos = false">
            {{ $tc("botoes.fechar") }}
          </v-btn>
          <v-btn
            depressed
            class="white--text"
            small
            color="toolbar"
            :disabled="disableTempos"
            @click="salvarTempos"
          >
            {{ $tc("eventos.salvartempos") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de cancelar evento -->
    <v-dialog v-model="dialogConfirmationCancelar" persistent width="500">
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          {{ $tc("msg.temcerteza") }}
          <v-sheet rounded class="px-2 mx-2 white--text orange darken-1">
            {{ $tc("botoes.cancelar") }}
          </v-sheet>
          {{ $tc("eventos.oevento") }}?
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogConfirmationCancelar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <v-textarea
            v-model="nota"
            :placeholder="$tc('msg.escrevanota')"
            :rows="1"
            class="pa-0 ma-0"
            row-height="25"
            hide-details
            auto-grow
            color="orange"
          ></v-textarea>
          <span class="caption">
            {{ $tc("eventos.notaobrigatoriacancelar") }}.
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-3"
            small
            text
            @click="dialogConfirmationCancelar = false"
          >
            {{ $tc("botoes.fechar") }}
          </v-btn>
          <v-btn
            depressed
            class="white--text"
            small
            color="orange"
            :disabled="!notaValid"
            @click="cancelarEvento"
          >
            {{ $tc("botoes.cancelar") }}
            <!-- Executar -->
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de enviar para aprovacao evento -->
    <v-dialog v-model="dialogConfirmation" persistent width="600">
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          {{ $tc("msg.temcerteza") }}
          <v-sheet rounded class="px-2 mx-2 white--text toolbar">
            {{ $tc("eventos.enviaraprovacao") }}
          </v-sheet>
          {{ $tc("eventos.oevento") }}?
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogConfirmation = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <v-textarea
            v-model="nota"
            :placeholder="$tc('msg.escrevanota')"
            :rows="1"
            class="pa-0 ma-0"
            row-height="25"
            hide-details
            auto-grow
            color="toolbar"
          ></v-textarea>
          <span class="caption">
            {{ $tc("eventos.notaobrigatoriaenviaraprovacao") }}.
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-3" small text @click="dialogConfirmation = false">
            {{ $tc("botoes.fechar") }}
          </v-btn>
          <v-btn
            depressed
            class="white--text"
            small
            color="toolbar"
            :disabled="!notaValid"
            @click="enviarAprovacao"
          >
            {{ $tc("botoes.executar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de aprovacao evento -->
    <v-dialog v-model="dialogAprovar" persistent width="500">
      <v-card :loading="loadingAprovacao">
        <v-card-title class="subtitle-1 lightgray">
          Aprovar Evento
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogAprovar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <v-textarea
            v-model="nota"
            :placeholder="$tc('msg.escrevanota')"
            :rows="1"
            class="pa-0 ma-0"
            row-height="25"
            hide-details
            auto-grow
            color="toolbar"
          ></v-textarea>
          <span class="caption">
            {{ $tc("eventos.notaobrigatoriaaprovar") }}.
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-3" small text @click="dialogAprovar = false">
            {{ $tc("botoes.fechar") }}
          </v-btn>
          <v-btn
            depressed
            class="white--text"
            small
            color="toolbar"
            :disabled="!notaValid"
            @click="aprovarEvento"
          >
            {{ $tc("eventos.aprovar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
  <v-card-text style="height: 351px; max-height: 351px;" v-else>
    <Carregando />
  </v-card-text>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  getEvento,
  putEvento,
  putCancelarEvento,
  putConcluirEvento,
  putEventoTags,
} from "@/services/api/eventos.api.js";
import { getVeiculos } from "@/services/api/veiculos.api.js";
import { getClienteAdm } from "@/services/api/clientes.api.js";
import {
  getEventosMembrosPorEvento,
  getEventosMembrosNotEqual,
  postEventosMembro,
  deleteEventosMembros,
} from "@/services/api/eventos-membros.api.js";
import { postEventosNota } from "@/services/api/eventos-notas.api.js";
import { getEventoTarefas } from "@/services/api/eventos-tarefas.api.js";
import { fetchAllTags, postTag } from "@/services/api/tags.api.js";
import { solicitaFeedback } from "@/services/api/feedback.api.js";

import { format, parseISO } from "date-fns";

export default {
  name: "EventoInformacoes",

  props: ["eventoId"],

  data() {
    return {
      evento: {},
      membros: null,
      dialogMembro: false,
      membro: {
        responsable: false,
      },
      loadingNovoMembro: false,
      veiculos: [],
      usersNotListed: [],
      dialogAgendar: false,
      agendarPara: false,
      time: null,
      menu2: false,
      loadingAgendamento: false,
      dialogConfirmation: false,
      dialogConfirmationCancelar: false,
      dialogAprovar: false,
      dialogTempos: false,
      loadingTempo: false,
      loadingAprovacao: false,
      menu_horario_inicio: false,
      menu_horario_fim: false,
      confirmationText: "",
      nota: "",
      eventoTarefas: [],
      search: "",
      tags: [],
      selectTags: [],
      loadingTags: false,
      responsavel: false,
      criador: false,
      cliente_end: null,
    };
  },

  computed: {
    ...mapState("Eventos", {
      status: (state) => state.status,
    }),
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
    ...mapState("Evento", {
      original: (state) => state.evento,
      eventosProdutos: (state) => state.eventosProdutos,
    }),
    agendadoParaFormatted() {
      return this.evento.agendado_data
        ? this.$moment(this.evento.agendado_data).format("DD/MM/YYYY")
        : "";
    },
    evento_id() {
      return this.$route.params.eventoId;
    },
    notaValid() {
      let valid = false;
      if (this.nota.length > 4) {
        valid = true;
      }
      return valid;
    },
    temVeiculo() {
      if (this.evento.exige_veiculo) {
        if (this.membro.veiculo_id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    temEstoque() {
      if (this.evento.exige_estoque) {
        if (this.eventosProdutos.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    validateTarefas() {
      let valid = true;
      this.eventoTarefas.forEach((item) => {
        if (item.obrigatorio) {
          if (item.valor === null) {
            if (item.tipo === "imagem" && item.imgs.length) {
              valid = true;
            } else {
              valid = false;
            }
          }
        }
      });

      return valid;
    },
    disableAgendamento() {
      let result = true;

      if (this.evento.agendado_data && this.evento.agendado_hora) {
        result = false;
      }

      return result;
    },
    disableTempos() {
      let result = true;

      if (this.evento.horario_inicio && this.evento.horario_fim) {
        result = false;
      }

      return result;
    },
    evento_adm_id() {
      return this.evento.adm_id;
    },
  },

  watch: {
    // original() {
    //   this.evento = this.lodash.cloneDeep(this.original);
    // }
    selectTags() {
      this.evento.tags = this.selectTags.join();
      this.updateTags();
    },
    time() {
      this.evento.agendado_hora = this.time;
    },
    evento_adm_id: {
      handler() {
        if (this.evento_adm_id) {
          this.getClienteAdmEnd();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions("Evento", {
      getEvento: "getEvento",
      upEvento: "upEvento",
      getEventosProdutos: "getEventosProdutos",
      reset: "reset",
    }),
    excerpt(text) {
      if (!text) return;
      if (text.length > 25) {
        return text.substr(0, 25) + "...";
      } else {
        return text;
      }
    },
    updateAgendamento() {
      this.loadingAgendamento = true;
      this.$Progress.start();

      let body = {
        agendado_data: this.evento.agendado_data,
        agendado_hora: this.evento.agendado_hora,
      };

      if (this.evento.agendado_data && this.evento.agendado_hora) {
        this.evento.status = 1;
        body.status = 1;
      }

      this.upEvento({ id: this.evento_id, evento: body })
        .then(() => {
          this.$toast.success(this.$tc("msg.atualizadosucesso"));
          this.dialogAgendar = false;
          this.loadingAgendamento = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadingAgendamento = false;
          this.dialogAgendar = false;
          this.$Progress.fail();
        });
    },
    getMembros() {
      getEventosMembrosPorEvento(`?evento_id=${this.evento_id}`)
        .then((response) => {
          this.membros = response;
          this.isResponsable();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEventosMembrosNotEqual() {
      getEventosMembrosNotEqual(this.evento_id)
        .then((response) => {
          this.usersNotListed = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMembro() {
      this.loadingNovoMembro = true;
      this.membro.evento_id = Number(this.evento_id);
      this.membro.criador = false;
      this.$Progress.start();
      postEventosMembro(this.membro)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.addsucesso"));
            this.membro = {};
            this.membro.responsable = false;
            this.dialogMembro = false;
            this.getMembros();
            this.getEventosMembrosNotEqual();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loadingNovoMembro = false;
        });
    },
    getVeiculos() {
      getVeiculos()
        .then((response) => {
          this.veiculos = response;
        })
        .catch((error) => console.log(error));
    },
    voltarEventos() {
      if (this.$route.matched[0].path) {
        this.$router.push(this.$route.matched[0].path);
      } else {
        this.$router.push(this.$route.matched[1].path);
      }
    },
    openDialogConfirmation(text) {
      if (this.temEstoque && this.validateTarefas) {
        if (text === "cancelar") {
          this.dialogConfirmationCancelar = true;
        }
        if (text === "enviar_aprovacao") {
          this.dialogConfirmation = true;
        }
        if (text === "aprovar") {
          this.dialogAprovar = true;
        }
      } else {
        if (text === "cancelar") {
          this.dialogConfirmationCancelar = true;
        } else {
          if (!this.temEstoque) {
            this.$toast.error(this.$tc("eventos.infomateriais"));
          }
          if (!this.validateTarefas) {
            this.$toast.error(this.$tc("eventos.finalizartarefas"));
          }
        }
      }
    },
    enviarAprovacao() {
      this.loading = true;
      this.$Progress.start();

      let notaObj = {};
      notaObj.evento_id = this.evento_id;
      notaObj.user_id = this.user_id;
      notaObj.nota = this.nota;

      putConcluirEvento(this.evento_id)
        .then(() => {
          postEventosNota(notaObj)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success(this.$tc("msg.concluidosucesso"));
                this.nota = "";
                this.voltarEventos();
                this.loading = false;
                this.$Progress.finish();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$Progress.fail();
            });
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.fail();
        });
    },
    cancelarEvento() {
      this.loading = true;
      this.$Progress.start();
      let notaObj = {};
      notaObj.evento_id = this.evento_id;
      notaObj.user_id = this.user_id;
      notaObj.nota = this.nota;

      putCancelarEvento(this.evento_id)
        .then(() => {
          this.$toast.success(this.$tc("msg.canceladosucesso"));
          postEventosNota(notaObj)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success(this.$tc("msg.criadosucesso"));
                this.nota = "";
                this.voltarEventos();
                this.$Progress.finish();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$Progress.fail();
            });
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    aprovarEvento() {
      this.loadingAprovacao = true;
      this.$Progress.start();

      let body = {
        data_aprovacao: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        usuario_aprovou: this.user_id,
        status: 4,
      };

      let notaObj = {};
      notaObj.evento_id = this.evento_id;
      notaObj.user_id = this.user_id;
      notaObj.nota = this.nota;

      this.upEvento({ id: this.evento_id, evento: body })
        .then(() => {
          postEventosNota(notaObj)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success(this.$tc("eventos.aprovadosucesso"));
                this.loadingAprovacao = false;
                this.dialogAprovar = false;
                this.nota = "";
                this.voltarEventos();
                this.$Progress.finish();
              }
            })
            .catch((error) => {
              this.loadingAprovacao = false;
              this.dialogAprovar = false;
              this.$Progress.fail();
            });
        })
        .catch((error) => {
          this.loadingAprovacao = false;
          this.dialogAprovar = false;
          this.$Progress.fail();
        });
    },
    salvarTempos() {
      this.loadingTempo = true;
      this.$Progress.start();

      let body = {
        horario_inicio: this.evento.horario_inicio,
        horario_fim: this.evento.horario_fim,
      };

      this.upEvento({ id: this.evento_id, evento: body })
        .then(() => {
          this.$toast.success(this.$tc("eventos.temposalvo"));
          this.getEvento();
          this.loadingTempo = false;
          this.dialogTempos = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadingTempo = false;
          this.dialogTempos = false;
          this.$Progress.fail();
        });
    },
    buscaUsuario(id) {
      let user = this.usuarios.find((item) => item.id === id);
      if (user) return user.name.charAt(0).toUpperCase() + user.name.slice(1);
    },
    isResponsable() {
      if (!this.user_id || !this.membros) return;
      let membro = this.membros.find((item) => item.user_id === this.user_id);
      if (membro) this.responsavel = membro.responsable;
      if (membro) this.criador = membro.criador;
    },
    M_getEventoTarefas() {
      this.eventoTarefas = [];
      getEventoTarefas(`?evento_id=${this.evento_id}`)
        .then((response) => {
          this.eventoTarefas = response;
        })
        .catch((error) => console.log(error));
    },
    getTags() {
      fetchAllTags()
        .then((response) => {
          this.tags = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTags() {
      this.loadingTags = true;
      this.$Progress.start();
      putEventoTags(this.evento_id, this.evento.tags)
        .then((response) => {
          if (response.status === 201) {
            this.$Progress.finish();
            this.loadingTags = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$Progress.fail();
          this.loadingTags = false;
        });
    },
    addTagGeral(tag) {
      if (!tag) return;
      this.$Progress.start();
      postTag(tag)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.search = "";
            this.selectTags.push(tag);
            this.getTags();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    delProduto(id) {
      this.$Progress.start();
      deleteEventosMembros(id)
        .then(() => {
          this.$toast.success(this.$tc("msg.removidosucesso"));
          this.$Progress.finish();
          this.getMembros();
          this.getEventosMembrosNotEqual();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    getFeedback() {
      this.$Progress.start();
      solicitaFeedback(this.evento_id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(this.$tc("msg.solicitadosucesso"));
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    getClienteAdmEnd() {
      getClienteAdm(this.evento_adm_id)
        .then((response) => {
          this.cliente_end = response.endereco;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.evento = this.lodash.cloneDeep(this.original);
    if (this.evento.tags && this.evento.tags.length) {
      this.selectTags = this.evento.tags.split(",");
    }
    this.getTags();
    this.getMembros();
    this.getVeiculos();
    this.getEventosMembrosNotEqual();
    if (!this.eventosProdutos.length) {
      this.getEventosProdutos(this.evento_id);
    }
    this.M_getEventoTarefas();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 14px 5px;
}
</style>
